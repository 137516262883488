// Importing necessary modules and components
"use client";
import React, { memo, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import CourseDesc from "./CourseDesc";
import SyllabusDetail from "../ui/SyllabusDetail";


// Icon component for the accordion icon
export const Icon = ({ id, open }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    className={`${
      id === open ? "rotate-180" : ""
    } h-5 w-5 transition-transform`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

// Shared Accordion component for all types
const AccordionComponent = ({ open, onHeaderClick, content }) => (
  <Accordion open={open} icon={<Icon id={content.id} open={open} />}>
    <AccordionHeader
      onClick={onHeaderClick}
      className={content.headerClassName}
    >
      {content.headerContent}
    </AccordionHeader>
    <AccordionBody className={content.bodyClassName}>
      {Array.isArray(content.bodyContent)
        ? content?.bodyContent?.map((course) => course)
        : content.bodyContent}
    </AccordionBody>
    {content.additionalBody && (
      <div className="bg-gray-800 h-[0.1px] w-full"></div>
    )}
  </Accordion>
);

// SharedAccordions component represents the accordions used for different sections
const Accordions = memo(({ c, i, card, type, allOpen }) => {
  // State to manage accordion open/close status
  const [open, setOpen] = useState(type !== "CATEGORY" ? 0 : 1);

  // Function to handle accordion open/close
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  // Mapping accordion content based on type
  const accordionContent = {
    CATEGORY: {
      headerClassName: "text-white hover:text-white flex justify-between",
      headerContent: c.name,
      bodyClassName: "flex items-center justify-center flex-wrap gap-x-4",
      bodyContent: card?.map((item, index) => (
        <CourseDesc
          key={index}
          description={item.description}
          name={item.name}
          level={item.level}
          category={c.key}
          type={item.courseType}
          courseKey={item.key}
          duration={item.duration}
        />
      )),
    },
    SYLLABUS: {
      headerClassName: "!border-none",
      headerContent: <SyllabusHeader
       index={i + 1} lesson={c} />,
      bodyContent: c?.tasksEntityList?.map((taskEntity, index) => (
        <SyllabusDetail key={index} a={taskEntity} />
      )),
      additionalBody: true,
    },
    FAQ: {
      headerContent: c?.question,
      bodyContent: c?.answer,
      bodyClassName:
        "flex items-center lg:text-xl text-base leading-[1.6] text-[#1f2a44]",
      headerClassName:
        "text-[#10162f] lg:text-xl max-[500px]:bg-red-500 text-base flex justify-between !border-none hover:!text-inherit",
      additionalBody: true,
    },
  };

  // Render the shared accordion component with dynamic content
  return (
    <AccordionComponent
      open={
        accordionContent[type]?.additionalBody
          ? allOpen || open === i + 1
          : open === i + 1
      }
      onHeaderClick={() => handleOpen(i + 1)}
      content={accordionContent[type]}
    />
  );
});

// Header component for Syllabus type
const SyllabusHeader = ({ index, lesson }) => (
  <div className="flex items-center w-full gap-4 justify-between py-4">
    <div className="rounded-full text-white bg-[#10162f] items-center justify-center w-[40px] h-[40px] sm:flex hidden">
      <div className="w-[40px] h-[40px] flex items-center justify-center">
        {index}
      </div>
    </div>
    <div className="!text-[#10162f] sm:w-[90%]  w-full">
      <div className="font-[600] text-[1.25rem] mb-2">{lesson?.name}</div>
      <div className="text-base font-normal">{lesson?.description}</div>
    </div>
  </div>
);

export default Accordions;
