"use client";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { Get } from "@/services/fetchServices";
import { FaCode } from "react-icons/fa";
import { PiInfinityBold } from "react-icons/pi";
import { useI18n } from "@/locales/client";
import Link from "next/link";
import Loader from "@/components/shared/Loader";

const CaruselSignUp = () => {
  const [size, setSize] = useState(null);
  const [images, setImages] = useState([]);
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const t = useI18n();

  const getImages = async () => {
    setLoading(true);
    try {
      const res = await Get("quiz/v1/pictures/isActive");
      setImages(res);
    } catch (error) {
      console.error("Failed to fetch images:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateSectionHeight = () => {
    const divElement = document.querySelector(".absolute-boxes");
    if (divElement) {
      const newHeight = divElement.offsetHeight;
      setDivHeight(newHeight < 300 ? newHeight - 226 : newHeight);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setSize(window.innerWidth);
    }
    getImages();
  }, []);

  useEffect(() => {
    updateSectionHeight();
    window.addEventListener("resize", updateSectionHeight);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", updateSectionHeight);
    };
  }, [images]);

  if (typeof window !== "undefined") {
    window.addEventListener("resize", () => {
      setSize(window.innerWidth);
    });
  }

  return (
    <div className="relative">
      {/* {loading && (
        <Loader height="500px"/>
      )} */}

      {!loading && images.length !== 0 && (
        <>
          <div className="pattern_swiper">
            <div className="swiperContainer">
              <Swiper
                navigation
                spaceBetween={30}
                modules={[Autoplay, Navigation]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                className="mySwiper"
              >
                {images.map((image,index) => (
                  <SwiperSlide key={image?.id}>
                    <div className="slider-img">
                      <Image
                        src={image?.image}
                        alt={image?.title || "Image"}
                        priority = {index === 0}
                        fill
                        objectFit="cover"
                      />
                      <div className="absolute inset-0 flex items-center justify-center mb-[6rem]">
                        <div className="text-white text-center">
                          <div className="content">
                            <p className="uppercase text-xl">
                              {image?.subtitle}
                            </p>
                            <h1 className="lg:text-[60px] font-bold text-[30px] md:text-[50px]">
                              {image?.title}
                            </h1>
                            <p className="text-xl">{image?.description}</p>
                          </div>
                          <div className="flex items-center justify-center mt-[10px]">
                            {image?.clickButton?.nameOfClick && (
                              <Link
                                aria-label="Link"
                                rel="canonical"
                                href={image?.clickButton?.link}
                                target="_blank"
                                className="block w-fit text-[17px] px-[32px] py-[12px] bg-[#0c5adb] hover:bg-[#00247e] duration-300 rounded-[4px]"
                              >
                                {image?.clickButton?.nameOfClick}
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="relative">
            <div style={{ height: divHeight }}>
              <div className="absolute-boxes">
                <div className="grid-box">
                  <div className="grid-item">
                    <div className="rotated">
                      <div>
                        <p className="rotated-icon">
                          <FaCode size={50} />
                        </p>
                        <p className="grid-box-title">Programming & Software Architecture</p>
                      </div>
                      <div>
                        <p className="grid-box-title">Practical trainings by experienced engineers</p>
                        <p>
                          Whether a beginner or an experienced developer, you
                          can benefit from courses like Java SE for beginners or
                          Microservice Development for experienced developers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="rotated">
                      <div>
                        <p className="rotated-icon">
                          <PiInfinityBold size={60} />
                        </p>
                        <p className="grid-box-title">Devops & Automation Engineering</p>
                      </div>
                      <div>
                        <p className="grid-box-title">From Help Desk to DevOps</p>
                        <p>
                          Explore our range of trainings from Help Desk and
                          Linux Foundation certifications to RHCSA and advanced
                          Linux Engineer courses, tailored up to DevOps engineer
                          levels, to suit your experience level and career
                          goals.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="rotated">
                      <div>
                        <p className="rotated-icon">
                          <img
                            src="/images/analytical.png"
                            width={70}
                            height={70}
                            alt="analytic"
                          />
                        </p>
                        <p className="grid-box-title">Business Analysis & Product ownership</p>
                      </div>
                      <div>
                        <p className="grid-box-title">Elevate Your Career with New Skills</p>
                        <p>
                          Our practical training programs empower you with
                          advanced abilities to translate intricate business
                          requirements into detailed API and technical
                          documentation for technical teams.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="rotated">
                      <div>
                        <p className="rotated-icon">
                          <img
                            src="/images/ai.png"
                            width={70}
                            height={70}
                            alt="ai"
                          />
                        </p>
                        <p className="grid-box-title">Data Science & Machine Learning </p>
                      </div>
                      <div>
                        <p className="grid-box-title">Learn "machine learning"</p>
                        <p>
                          Develop a robust AI skill set tailored to today's
                          workplace demands. Master AI alongside essential
                          mathematical concepts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CaruselSignUp;
