// Import necessary libraries and components
"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useState, useCallback } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { javascript } from "@codemirror/lang-javascript";
import { andromeda } from "@uiw/codemirror-theme-andromeda";
import ReactCodeMirror from "@uiw/react-codemirror";

import { useI18n } from "@/locales/client";

const TaskCodeEditor = () => {
  // State variables for output and code
  const [output, setOutput] = useState("");
  const t = useI18n();
  const [code, setCode] = useState(
    "const firstName = 'Ingress';\n const lastName = 'Academy';\n console.log(`Welcome, ${firstName} ${lastName}!`);"
  );

  // Use useCallback to memoize the handleRunCode function
  const handleRunCode = useCallback(() => {
    try {
      const logs = [];
      const originalConsoleLog = console.log;
      console.log = (...args) => {
        logs.push(args.map((arg) => JSON.stringify(arg)).join(" "));
      };
      eval(code);
      console.log = originalConsoleLog;
      setOutput(logs.join("\n"));
    } catch (e) {
      setOutput(`Error: ${e.message}`);
    }
  }, [code]);

  // Render the component
  return (
    <section className="code-editor">
      <div className="inner-code-editor ">
        <div className="editor-relative box">
          {/* Header section with logo and code/output navigation */}
          <div className="editor-header box">
            <div className="header">
              <div className="logo">
                <div>
                  {/* Link to the homepage with the Ingress logo */}
                  <Link aria-label="Home" rel='canonical' href="/" target="_blank">
                    <Image
                      src="/images/logo/ingress-logo-new.png"
                      width={45}
                      height={45}
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              {/* Code/output navigation buttons */}
              <div className="code-output box1">
                <div className="b">
                  <div>
                    <IoIosArrowBack size={20} />
                    <div>{t("code")}</div>
                  </div>
                  <div>
                    <div>{t("output")}</div>
                    <IoIosArrowForward size={20} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Code editor and output display */}
          <div className="code box">
            <div className="editor">
              {/* Lazy-loaded ReactCodeMirror component */}
              <ReactCodeMirror
                value={code}
                className="text-base"
                height="300px"
                extensions={javascript()}
                onChange={(value) => setCode(value)}
                theme={andromeda}
              />
            </div>
            {/* Output display */}
            <div className="output box">
              <pre>{output}</pre>
            </div>
          </div>

          {/* Button to run the code */}
          <div className="run box">
            <button className="run" onClick={handleRunCode}>
              {t("run")}
            </button>
          </div>
        </div>
      </div>

      {/* Section for continuing lessons */}
      <div className="continue">
        <span>{t("bw")}</span>
        <h2>{t("slt")}</h2>
        <p>{t("codeContent")}</p>
        {/* Links for continuing lessons and exploring more beginner courses */}
        <div>
          <Link
          aria-label="Training Roadmaps"
            href="https://ingressacademy.gitbook.io/training-roadmaps"
            target="_blank"
          >
            {t("tr")}
          </Link>
          <Link aria-label="Beginner Courses" rel='canonical' href="/courses?level=Beginner">{t("bc")}</Link>
        </div>
      </div>
    </section>
  );
};

// Export the TaskCodeEditor component
export default TaskCodeEditor;
