"use client";
import Link from "next/link";
import React, { useRef, useEffect, useState } from "react";
import { FaLinkedinIn } from "react-icons/fa";

import { useQuizContext } from "@/context/context";
import { useI18n } from "@/locales/client";

const Team = () => {
  const { team } = useQuizContext() || { team: [] };
  const [displayedMembers, setDisplayedMembers] = useState([]);
  const [h, setH] = useState();
  const titleRef = useRef();

  const t = useI18n();

  function getRandomElements(arr, numElements) {
    // Make a copy of the original array to avoid modifying it
    const copyArray = [...arr];

    // Shuffle the array using Fisher-Yates algorithm
    for (let i = copyArray?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
    }

    // Return the first 'numElements' elements from the shuffled array
    setDisplayedMembers(copyArray.slice(0, numElements));
  }
  const ratio = () => {
    let ratio;
    if (window.innerWidth >= 968) {
      ratio = 21;
    } else if (window.innerWidth < 968 && window.innerWidth > 720) {
      ratio = 30;
    } else if (window.innerWidth < 720 && window.innerWidth > 460) {
      ratio = 45;
    } else {
      ratio = 90;
    }
    setH(((window.innerWidth * ratio) / 100) * 1.3);
  };
  useEffect(() => {
    ratio();
  }, []);
  useEffect(() => {
    const onResize = () => ratio();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    getRandomElements(team, 12);
  }, [team]);

  const handleSeeMore = () => {
    const remainingElements = team.filter(
      (member) => !displayedMembers.includes(member)
    );

    setDisplayedMembers((prevElements) => [
      ...prevElements,
      ...remainingElements,
    ]);
  };
  const handleSeeLess = () => {
    setDisplayedMembers((prev) => prev.slice(0, 4));
  };
  return (
    <>
      {team.length > 0 && (
        <section className="our-team">
          <div className="inner-team">
            <div className="team-header">
              <div className="header-container">
                <div className="team-title">
                  <div>
                    <p></p>
                  </div>
                  <p>{t("team")}</p>
                </div>
                <h2>
                  {t("ei")}
                  <span>{t("rwe")}</span>
                </h2>
              </div>
              <button onClick={handleSeeMore}>{t("vam")}</button>
            </div>
            <div className="flex mb-[24px]">
              {displayedMembers?.map((member) => (
                <div className="flex-img-item" key={member?.id}>
                  <Link
                    aria-label="LinkedIn"
                    target="_blank"
                    className="linkedinLink"
                    href={member?.linkedinLink}
                  >
                    <FaLinkedinIn size={15} />
                  </Link>
                  <Link
                    aria-label="LinkedIn"
                    href={member?.linkedinLink}
                    target="_blank"
                  >
                    <div className="member-info">
                      <div>
                        <div className="member-image">
                          <img
                            loading="lazy"
                            src={member.image}
                            alt={member.name}
                          />
                          <div className="member-image-shadow"></div>
                        </div>
                        <div className="group">
                          <span className="bold">{member?.name}</span>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: member?.workPlace,
                            }}
                            className="ellipsis"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            {team?.length > 4 && (
              <div className="see-more-members">
                <div>
                  {displayedMembers.length !== team.length ? (
                    <button onClick={handleSeeMore}>{t("vam")}</button>
                  ) : (
                    <button onClick={handleSeeLess}>{t("sl")}</button>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Team;
