import Link from "next/link";
import React from "react";
import { CiLock } from "react-icons/ci";
import { PiBookThin } from "react-icons/pi";

const SyllabusDetail = ({ a }) => {
  const type = a?.typeResponse?.type
  let Icon;
  switch(type){
    case 'Lesson':
      Icon = PiBookThin
      break;
    default:
      Icon = CiLock  
      break;
  }

  return (
    <div className="block w-full sm:ml-12 mb-4 hover:text-blue-800 cursor-pointer duration-100 text-[#333333] ">
      <Link aria-label='Resource' rel='canonical' href={`${a?.typeResponse?.url}`} target="_blank">
      <div className="flex items-center gap-4">
        <div className="border border-black h-[35px] p-[0.375rem] w-[35px] flex items-center justify-center rounded-full text-2xl text-black">
          <Icon size={30} style={{marginLeft:"0.01rem"}}/>
        </div>
        <div className="flex md:items-center justify-between w-full md:flex-row flex-col text-base">
          <div>{type}</div>
          <div className="sm:w-[80%] w-full overflow-hidden whitespace-nowrap text-ellipsis">{a?.taskName}</div>
        </div>
      </div>
      </Link>
    </div>
  );
};

export default SyllabusDetail;
