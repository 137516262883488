"use client";
import { useEffect, useState } from "react";
import Slider from "react-slick";

import { Get } from "@/services/fetchServices";
import { useI18n } from "@/locales/client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  const [partners, setPartners] = useState([]);

  const t = useI18n();

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: partners.length <= 12 ? 1 : 2,
    slidesPerRow: partners.length <= 6 ? partners.length : 6,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 3,
          arrows: false,
          slidesPerRow: 2,
        },
      },
    ],
  };
  const getPartners = async () => {
    const res = await Get("quiz/v1/partner/list");
    setPartners(res?.content);
  };

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <section>
      {partners?.length > 0 && (
        <div className="bg-[#F5FCFF]">
          <div className="pb-[76px] mx-auto w-[90%]">
            <div className="text-center pt-[25px] lg:pt-[50px] font-['Muli sans_serif'] mb-4">
              <h2 className="text-[#0c5adb] tracking-[5px] text-base font-bold uppercase mb-2">
                {t("customers")}
              </h2>
              <p className="text-[#2E3131] text-[28px] text-center font-[700] pb-4 max-w-[400px] mx-auto">
                {t("customersContent")}
              </p>
            </div>
            <div
              className={`partners ${partners.length < 12 && "partners-flex"}`}
            >
              {partners.length > 12 ? (
                <Slider {...settings}>
                  {partners.map((partner, index) => (
                    <div
                      key={index}
                      className="slick-item partner-item bg-[#f5f6fa] rounded-2xl p-6 shadow-md"
                    >
                      <img
                        src={partner?.image}
                        alt={partner?.name}
                        title={partner?.name}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                partners.map((partner, index) => (
                  <div
                    key={index}
                    className="slick-item partner-item bg-[#f5f6fa] rounded-2xl p-6 shadow-md"
                  >
                    <img
                    loading="lazy"
                      src={partner?.image}
                      alt={partner?.name}
                      title={partner?.name}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Partners;
