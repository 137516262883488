import React, { memo } from "react";
import { HiOutlineChartBar } from "react-icons/hi";
import Link from "next/link";
import { useI18n } from "@/locales/client";

// CourseDesc component represents the description of a course.
// It is a memoized functional component.
const CourseDesc = ({
  description,
  name,
  level = "",
  style = {},
  type,
  classification = "",
  category,
  url,
  duration = "",
  courseKey = "",
}) => {
  if (!name) {
    return <></>;
  } else {
    const t = useI18n();
    let customizeClassName;
    const formattedDescription = (
      <div dangerouslySetInnerHTML={{ __html: description }} />
    );

    switch (type) {
      case "Article":
        customizeClassName = "article";
        break;
      case "Skill Path":
        customizeClassName = "skill-path";
        break;
      case "Career Path":
        customizeClassName = "career-path";
        break;
      default:
        customizeClassName = "other";
        break;
    }
    return (
      // Container section for the course description with dynamic CSS classes and styles.
      <section
        className={`${classification} ${customizeClassName}-parent course-containers`}
        style={style}
      >
        {/* Link to navigate to the course page. */}
        <Link
        aria-label="Course"
        rel="canonical"
          href={
            category
              ? `/courses/${encodeURIComponent(category)}/${encodeURIComponent(
                  courseKey
                )}`
              : url
              ? url
              : "/"
          }
          target={`${url ? "_blank" : "_self"}`}
        >
          {/* Course description container with dynamic styles. */}
          <div
            className="course-desc"
            style={classification !== "" ? { border: "1px solid black" } : {}}
          >
            {/* Type indicator for the course (e.g., Free Course, Article, Skill path). */}
            <div className={`course-free ${customizeClassName}`}>{type}</div>
            {/* Course title */}
            <div className="course-title">{name}</div>
            {/* Course sub-description */}
            <div className="course-sub-desc">{formattedDescription}</div>

            {/* Level indicator with an optional horizontal rule and icon. */}
            <div className="level">
              {level && (
                <>
                  <div className="hr"></div>
                  <div className="flex">
                    <div className="flex">
                      <HiOutlineChartBar size={20} />
                      <div className="text">{level}</div>
                    </div>
                    <div className="duration">
                      <span>{duration}</span> {t("months")}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Link>
      </section>
    );
  }
};

// Memoizing the CourseDesc component for performance optimization.
export default memo(CourseDesc);
