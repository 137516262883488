"use client";
import React, { memo } from "react";

import { useI18n } from "@/locales/client";

const StartLearning = () => {
  const t = useI18n();
  return (
    <>
      <div className="popular-course">{t("courses")}</div>
    </>
  );
};

export default memo(StartLearning);
