// Import necessary dependencies
"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";

// Import components
import { useQuizContext } from "@/context/context";
import { useI18n } from "@/locales/client";
import StartLearning from "../StartLearning";
import Accordions from "@/components/shared/Accordions";
import CourseDesc from "@/components/shared/CourseDesc";

const Courses = () => {
  // Access data from context
  const { allCategories, allCourses } = useQuizContext() || {
    allCategories: [],
    allCourses: [],
  };

  // State to track the active category and filtered courses
  const [activeCategory, setActiveCategory] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);

  const t = useI18n();
  // Effect to update filtered courses when active category changes
  useEffect(() => {
    setFilteredCourses(
      allCourses
        ?.filter(
          (course) =>
            course?.parentId ===
            (activeCategory === "" ? allCategories?.[0]?.id : activeCategory)
        )
        ?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))
    );
  }, [allCourses, activeCategory]);

  // Render the component
  return (
    <section className="popular-courses">
      <div>
        <StartLearning />

        {/* Category Accordions */}
        <div className="category-accordion ">
          {allCategories?.map((category, index) => (
            <Accordions
              key={index}
              type="CATEGORY"
              c={category}
              i={index}
              card={allCourses
                ?.filter((course) => course?.parentId === category?.id)
                ?.sort(
                  (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)
                )}
            />
          ))}
        </div>

        {/* Categories for mobile */}
        <div className="categories mobile">
          {allCategories?.map((category, index) => (
            <div
              key={index}
              className={`${
                activeCategory === category?.id ||
                (activeCategory === "" && index === 0)
                  ? "selected"
                  : ""
              } pointer`}
              onMouseEnter={() => setActiveCategory(category?.id)}
            >
              {category?.name}
            </div>
          ))}
        </div>

        {/* Course Descriptions */}
        <div className="course-desc-parent accordion-category">
          {filteredCourses?.map(
            (
              { key,courseType, duration, name, description, level, parentId },
              index
            ) => (
              <CourseDesc
                key={index}
                courseKey ={key}
                description={description}
                name={name}
                level={level}
                type={courseType}
                duration={duration}
                category={allCategories.find((c) => c.id === parentId)?.key}
              />
            )
          )}
        </div>

        {/* Explore full catalog link */}
        <div className="full-catalog">
          <Link aria-label="Courses" rel='canonical' href="/courses">{t("efc")}</Link>
        </div>
      </div>
    </section>
  );
};

export default Courses;
