"use client";
import { useEffect, useState } from "react";

import CaruselSignUp from "../ui/carusel/CaruselSignUp";
import AuthContent from "../ui/authentication/AuthContent";
import Auth from "../ui/authentication/Auth";
import TaskCodeEditor from "../ui/home/TaskCodeEditor";
import Team from "../ui/home/Team";
import Partners from "../ui/home/Partners";
import Courses from "../ui/home/Courses";
import { useQuizContext } from "@/context/context";
import Loader from "../shared/Loader";



const HomePage = ({ courseId }) => {
  const { loading } = useQuizContext() || {};

  if (loading) return <Loader height="70vh" />;
  return (
    <>
      <div className="flex flex-col items-center mx-auto justify-center">
        <div className="w-full mb-5">
          <CaruselSignUp />
        </div>
        <div className="home-content-container">
          <AuthContent />
          <Auth pageUrl="register" courseId={courseId} />
        </div>
      </div>
      <Courses />
      <TaskCodeEditor />
      <Team />
      <Partners />
    </>
  );
};

export default HomePage;
