"use client";
import React from "react";
import Image from "next/image";

import { useI18n } from "@/locales/client";
import Link from "next/link";

const AuthContent = () => {
  
  const t = useI18n();

  return (
    <section className="auth-content">
      <div className="auth-content-container">
        <p className="auth-title">{t("toss")}</p>
        <p className="auth-header">Ingress Academy</p>
        <p className="auth-header auth-header-blue">
          <span>{t("authTitle")}</span>
        </p>
        <ul>
          <li>
            <div className="list-item">
              <div className="list-icon">
                <Image
                  src="/images/client.png"
                  height={40}
                  width={40}
                  alt="consultation"
                  loading="lazy"
                />
              </div>
              <div>
                <p className="!text-black font-bold">{t("gfc")}</p>
                <p>{t("gfcContent")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-item">
              <div className="list-icon">
                <Image
                  src="/images/book.png"
                  height={40}
                  width={40}
                  alt="lesson"
                  loading="lazy"
                />
              </div>
              <div>
                <p className="!text-black font-body">{t("jodl")}</p>
                <p>{t("jodlContent")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-item">
              <div className="list-icon">
                <Image
                  src="/images/webinar.png"
                  height={40}
                  width={40}
                  alt="webinar"
                  loading="lazy"
                />
              </div>
              <div>
                <p className="!text-black font-bold">{t("fuy")}</p>
                <p>{t("fuyContent")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-item">
              <div className="list-icon">
                <Image
                  src="/images/growth.png"
                  height={40}
                  width={40}
                  alt="success story"
                  loading="lazy"
                />
              </div>
              <div>
                <p className="!text-black font-bold">{t("rss")}</p>
                <p>{t("rssContent")}</p>
              </div>
            </div>
          </li>
          <li>
            <Link title="Read more success stories" aria-label="View all success stories" href='https://ingressacademy.gitbook.io/success-stories/' target='_blank' rel="noopener noreferrer">{t("rm")}</Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default AuthContent;
